import { createBrowserRouter } from 'react-router-dom';
import { Suspense, createRef, lazy } from 'react';

//
const Root = lazy(() => import('../components/Root'));

//
const Login = lazy(() => import('./01-Login/Login'));
import LanguageChoose from './01-Login/components/LanguageChoose';
import Logo from './01-Login/components/Logo';

//
const Home = lazy(() => import('./03-Home/Home'));

//
const Mine = lazy(() => import('./04-Mine/Mine'));
const Vip = lazy(() => import('./04-Mine/vip/Vip'));
const BIF = lazy(() => import('./04-Mine/bif/BIF'));
const Swap = lazy(() => import('./04-Mine/computingPower/ComputingPower'));
const SwapRecord = lazy(() => import('./04-Mine/computingPower/SwapRecord'));

const BIFIntroduction = lazy(() =>
  import('./04-Mine/bifIntroduction/BIFIntroduction')
);
const FundsRecords = lazy(() => import('./04-Mine/fundsRecords/FundsRecords'));
const Notice = lazy(() => import('./04-Mine/notice/Notice'));
const NoticeDetail = lazy(() => import('./04-Mine/notice/NoticeDetail'));
const NoticeActivity = lazy(() => import('./04-Mine/notice/NoticeActivity'));
const NoticeActivityDetail = lazy(() =>
  import('./04-Mine/notice/NoticeActivityDetail')
);
const ProjectAnnouncement = lazy(() =>
  import('./04-Mine/projectAnnouncement/ProjectAnnouncement')
);
const IncomeDetails = lazy(() =>
  import('./04-Mine/incomeDetails/IncomeDetails')
);

//
const ParticipateMain = lazy(() => import('./05-Participate/ParticipateMain'));

const ErrorPage = lazy(() => import('./02-404/ErrorPage'));

export const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <Suspense fallback={<></>}>
        <Root />
      </Suspense>
    ),
    errorElement: <ErrorPage />,
    children: [
      {
        path: 'home',
        element: (
          <Suspense fallback={<></>}>
            <Home />
          </Suspense>
        ),
        nodeRef: createRef(),
      },
      {
        path: 'participate',
        element: (
          <Suspense fallback={<></>}>
            <ParticipateMain />
          </Suspense>
        ),
        nodeRef: createRef(),
      },
      {
        path: 'mine',
        element: (
          <Suspense fallback={<></>}>
            <Mine />
          </Suspense>
        ),
        nodeRef: createRef(),
      },
    ],
  },
  {
    path: '/login',
    element: (
      <Login>
        {/* logo模块 */}
        <Logo />

        {/* 语言选择模块 */}
        <LanguageChoose />
      </Login>
    ),
    nodeRef: createRef(),
  },
  {
    path: '/bifIntroduction',
    element: (
      <Suspense fallback={<></>}>
        <BIFIntroduction />
      </Suspense>
    ),
    nodeRef: createRef(),
  },
  {
    path: '/bif',
    element: (
      <Suspense fallback={<></>}>
        <BIF />
      </Suspense>
    ),
    nodeRef: createRef(),
  },
  {
    path: '/fundsRecords',
    element: (
      <Suspense fallback={<></>}>
        <FundsRecords />
      </Suspense>
    ),
    nodeRef: createRef(),
  },
  {
    path: '/notice',
    element: (
      <Suspense fallback={<></>}>
        <Notice />
      </Suspense>
    ),

    nodeRef: createRef(),
  },
  {
    path: '/detail/:id',
    element: (
      <Suspense fallback={<></>}>
        <NoticeDetail />
      </Suspense>
    ),

    nodeRef: createRef(),
  },
  {
    path: '/incomeDetails',
    element: (
      <Suspense fallback={<></>}>
        <IncomeDetails />
      </Suspense>
    ),
    nodeRef: createRef(),
  },
  {
    path: '/vip',
    element: (
      <Suspense fallback={<></>}>
        <Vip />
      </Suspense>
    ),
    nodeRef: createRef(),
  },
  {
    path: '/noticeActivity',
    element: (
      <Suspense fallback={<></>}>
        <NoticeActivity />
      </Suspense>
    ),
    nodeRef: createRef(),
  },
  {
    path: '/noticeActivityDetail/:id',
    element: (
      <Suspense fallback={<></>}>
        <NoticeActivityDetail />
      </Suspense>
    ),
    nodeRef: createRef(),
  },
  {
    path: '/projectAnnouncement',
    element: (
      <Suspense fallback={<></>}>
        <ProjectAnnouncement />
      </Suspense>
    ),
    nodeRef: createRef(),
  },
  {
    path: '/swap',
    element: (
      <Suspense fallback={<></>}>
        <Swap />
      </Suspense>
    ),
    nodeRef: createRef(),
  },
  {
    path: '/swapRecord',
    element: (
      <Suspense fallback={<></>}>
        <SwapRecord />
      </Suspense>
    ),
    nodeRef: createRef(),
  },
]);
