import React, { useState } from 'react';
import { ArrowDown, Success } from '@react-vant/icons';
import { Image } from 'react-vant';
import { useTranslation } from 'react-i18next';

const LanguageChoose = () => {
  const [lang, setLang] = useState(
    window.localStorage.getItem('i18nextLng') || 'zhTW'
  );
  const [show, setShow] = useState(false);
  const { i18n } = useTranslation();

  const DATA = [
    {
      id: 'zh',
      icon: '/assets/imgs/01/c2.png',
      title: '简体中文',
    },
    {
      id: 'zhTW',
      icon: '/assets/imgs/01/c2.png',
      title: '繁體中文',
    },
    {
      id: 'en',
      icon: '/assets/imgs/01/c1.png',
      title: 'English',
    },
    {
      id: 'AR',
      icon: '/assets/imgs/01/c3.png',
      title: 'العربية',
    },
  ];

  const getLangTitle = () => {
    if (lang === 'zh-CN') {
      setLang('zhTW');
    }

    const title = DATA?.filter(item => item?.id === lang)[0]?.title;

    return title;
  };

  return (
    <div className=' flex justify-end relative flex-1'>
      <div
        className=' py-[.94rem] pr-[.88rem] text-[.75rem] text-white flex items-center w-fit pl-10  pb-[.31rem]'
        onClick={() => setShow(!show)}>
        <span className='mr-[.63rem]'>{getLangTitle()}</span>
        <span className=''>
          <ArrowDown />
        </span>
      </div>

      <List
        list={DATA}
        setLang={setLang}
        lang={lang}
        setShow={setShow}
        i18n={i18n}
        show={show}
      />
    </div>
  );
};

export default LanguageChoose;

const List = ({ list, setLang, lang, setShow, i18n, show }) => {
  const showClass =
    'absolute top-[120%] right-[.63rem] bg-[#0F393D] text-white rounded-xl z-10 transition-all duration-300 overflow-hidden p-2';

  const hiddenClass =
    'absolute top-[120%] right-[.63rem] bg-[#0F393D] text-white rounded-xl z-10 transition-all duration-300 overflow-hidden p-0 h-0';

  return (
    <div className={show ? showClass : hiddenClass}>
      {list.map(item => {
        return (
          <Item
            key={item.id}
            item={item}
            setLang={setLang}
            lang={lang}
            setShow={setShow}
            i18n={i18n}
          />
        );
      })}
    </div>
  );
};

const Item = ({ item, setLang, lang, setShow, i18n }) => {
  const handelChange = () => {
    setShow(false);

    if (lang === item.id) {
      return;
    }

    setLang(item.id);
    // console.log('item.id', item.id);
    i18n.changeLanguage(item.id);
  };

  return (
    <div
      className=' flex items-center justify-between mb-[12px] last:mb-[0]'
      onClick={handelChange}>
      <div className=' flex items-center flex-1 mr-[.63rem]'>
        <div className=' w-[.88rem] aspect-square rounded-full overflow-hidden mr-[.63rem]'>
          <Image
            fit='fill'
            src={item.icon}
          />
        </div>
        <div className=' text-[.69rem]'>{item.title}</div>
      </div>

      {/*  */}
      <div className=' w-[1rem] aspect-square text-[1rem]'>
        {lang === item.id && <Success />}
      </div>
    </div>
  );
};
