import React from 'react';
import ReactDOM from 'react-dom/client';
import 'abortcontroller-polyfill/dist/abortcontroller-polyfill-only';

// 全局样式
import './index.css';
// import App from './App';
// 第三方包
import './i18n';
import * as Sentry from '@sentry/react';
import { CaptureConsole } from '@sentry/integrations';

// 创建的路由表
// import { router } from './pages';
import App from './App';

Sentry.init({
  dsn: 'https://65f3fc9111e316ada96d98d5b702226f@o4505899264638976.ingest.sentry.io/4505899265884160',
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [
        /^https:\/\/api\.bifverse\.com/,
        /^https:\/\/api-test\.bifverse\.com/,
      ],
    }),
    new CaptureConsole({
      levels: ['error'],
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ReactDOM.createRoot(document.getElementById('root')).render(
  // <React.StrictMode>
  <App></App>
  // </React.StrictMode>
);
