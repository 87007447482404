/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import { RouterProvider } from 'react-router-dom';
import { Notify } from 'react-vant';

import { router } from './pages';
import { useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/browser';

export default function App() {
  const { t } = useTranslation();

  // 初始化
  useEffect(() => {
    async function start() {
      const addressList = await window.ethereum.request({
        method: 'eth_requestAccounts',
      });

      const newAddress = addressList[0];
      const oldAddress = window.sessionStorage.getItem('address');

      if (oldAddress === null) return;
      if (newAddress === oldAddress) return;

      Notify.show({ type: 'danger', message: t('Notify2.tip2') });
      window.localStorage.removeItem('token');
      Sentry.setUser(null);

      return;
    }

    start();
  }, []);

  return <RouterProvider router={router} />;
}
