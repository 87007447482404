/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Image } from 'react-vant';
import { motion } from 'framer-motion';

const LOGO_BG = '/assets/imgs/01/logo_bg.png';
const LOGO_IMAGE = '/assets/imgs/01/logo.png';

const Logo = () => {
  const [bgImgLoad, setBgImgLoad] = useState(false);
  const [mainImgLoad, setMainImgLoad] = useState(false);

  return (
    <motion.div
      initial={{ y: '-50px' }}
      animate={{ y: 0 }}
      exit={{ y: '-50px' }}
      transition={{
        duration: 0.75,
        ease: 'easeInOut',
      }}
      className='  relative ml-[20px]'>
      <div
        className='flex justify-center -z-10 w-[114px] '
        style={{
          backgroundImage: `url('/assets/imgs/01/logo_bg-small.png')`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: bgImgLoad ? '0 0' : ' 100% 100%',
          backgroundPosition: 'top',
        }}>
        <Image
          className={
            bgImgLoad
              ? ' opacity-100 transition-all duration-700'
              : ' opacity-0 transition-all duration-700'
          }
          fit='contain'
          src={LOGO_BG}
          onLoad={() => setBgImgLoad(true)}
        />

        <div
          className=' absolute top-[50%] -translate-y-[50%] left-[50%] -translate-x-[50%] w-[79px]'
          style={{
            backgroundImage: `url('/assets/imgs/01/logo-small.png')`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: mainImgLoad ? '0 0' : ' 100% 100%',
            backgroundPosition: 'top',
          }}>
          <Image
            className={
              mainImgLoad
                ? ' opacity-100 transition-all duration-700'
                : ' opacity-0 transition-all duration-700'
            }
            fit='contain'
            src={LOGO_IMAGE}
            onLoad={() => setMainImgLoad(true)}
          />
        </div>
      </div>
    </motion.div>
  );
};

export default Logo;
